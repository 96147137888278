<template>
    <v-modal :is-open="isOpen"
        @close="close">
        <div class="relative z-10 flex h-[100px] items-center overflow-hidden px-10"
            :class="{
                'text-white': depth === 0,
            }">
            <button v-if="depth > 0"
                type="button"
                class="w-max text-left text-xl outline-none transition-opacity duration-300
                    hover:text-gray-600 focus-visible:outline-2
                    focus-visible:outline-blue-600 active:text-gray-400"
                :aria-label="t('aria.nav-back')"
                @click="removeSubMenu">
                <v-icon icon="arrow-left" />
            </button>

            <button type="button"
                class="ml-auto w-max text-right
                    text-xl outline-none
                    focus-visible:outline-2 focus-visible:outline-blue-600"
                :class="{
                    'hover:text-gray-200 active:text-white': depth === 0,
                    'hover:text-gray-600 active:text-gray-400': depth > 0,
                }"
                :aria-label="t('aria.nav-close')"
                @click="close">
                <v-icon icon="close" />
            </button>
        </div>

        <div v-if="navigation"
            class="absolute inset-0 overflow-hidden">
            <div class="flex h-full translate-x-[var(--depth)] flex-nowrap
                bg-[color:var(--background-color)] text-[color:var(--color)]
                transition-transform ease-in-out"
                :class="{
                    'duration-0': skipTransition,
                    'duration-300': !skipTransition,
                }"
                :style="{
                    '--depth': `${depth * -100}%`,
                    '--background-color': backgroundColor,
                    '--color': fontColor,
                }">
                <v-header-nav-page-main
                    :inert="depth !== 0"
                    :font-color="fontColor"
                    :navigation="navigation"
                    @open="addSubMenu" />

                <v-header-nav-page-sub v-for="subMenu, index in subMenus"
                    :key="subMenu.id"
                    :sub-menu="subMenu"
                    :inert="depth !== index + 1"
                    @open="addSubMenu" />
            </div>
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
import 'wicg-inert';
import { refAutoReset, watchDebounced } from '@vueuse/core';
import type { TreeItem } from '~/server/api/navigation.get';

const properties = defineProps<{
    isOpen: boolean
    section?: string
}>();

const emit = defineEmits<{
    (event: 'close'): void
}>();

const { t } = useI18n();
const { csrf } = useCsrf();

const skipTransition = refAutoReset(false, 300);

const { data: navigation } = await useFetch('/api/navigation', {
    headers: {
        ...csrf ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'csrf-token': csrf,
        } : {},
    },
});

const headerContent = useStoryblokStory('global/header');

const backgroundColor = computed<string | undefined>(() => (
    headerContent.value?.content?.background_color.color === undefined
        ? '#EF1C35'
        : headerContent.value?.content?.background_color?.color
));

const fontColor = computed<string | undefined>(() => (
    headerContent.value?.content?.font_color === undefined
        ? '#FFF'
        : headerContent.value?.content?.font_color
));

const subMenus = ref<TreeItem[]>([]);

const depth = ref(0);

function addSubMenu(item: TreeItem) {
    if (!item.children || item.children.length === 0) {
        return;
    }

    subMenus.value.push(item);
    depth.value += 1;
}

function removeSubMenu() {
    depth.value -= 1;

    setTimeout(() => {
        subMenus.value.pop();
    }, 300);
}

watchDebounced(() => properties.isOpen, (isOpen) => {
    if (!isOpen) {
        depth.value = 0;
    }
}, { debounce: 300 });

watch(() => properties.section, (section) => {
    if (!navigation.value) {
        return;
    }

    skipTransition.value = true;

    if (section === 'rooms') {
        addSubMenu(navigation.value[0]);
    } else if (section === 'products') {
        addSubMenu(navigation.value[1]);
    }
});

function close() {
    emit('close');

    setTimeout(() => {
        subMenus.value = [];
    }, 300);
}

const router = useRouter();

router.beforeEach(() => {
    close();
});
</script>
