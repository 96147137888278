/*
    More redirects can be added here when needed.
*/

// eslint-disable-next-line consistent-return
const redirectMiddleware = defineNuxtRouteMiddleware((to) => {
    if (to.path.includes('/etusivu') || to.path.includes('/koduleht')) {
        // setting the redirect code to '301 Moved Permanently'
        return navigateTo('/', { redirectCode: 301 });
    }
});

export default redirectMiddleware;
