import type { ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';

export function useStoryblokStory(path: string, parameters?: ISbStoriesParams) {
    const api = useStoryblokApi();
    const { transform } = useStoryblokTransformer();

    const { data } = useAsyncData(
        path,
        () => api.getStory(useTrim(path, '/'), {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...parameters,
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
        {
            transform: (response) => ({
                ...usePick(response.data.story, [
                    'name',
                    'id',
                    'uuid',
                    'slug',
                    'full_slug',
                    'position',
                ]),
                content: transform(response.data.story.content),
            }) as ISbStoryData,
        },
    );

    return computed(() => data.value);
}
