<template>
    <component :is="tag"
        :to="isParent ? undefined : item.url"
        :type="isParent ? 'button' : undefined"
        class="flex items-center text-left hover:underline">
        <span v-if="item.type === 'category' && item.image"
            class="relative mr-4 size-10 overflow-hidden rounded-full">
            <nuxt-picture
                class="size-10"
                provider="storyblok"
                loading="lazy"
                height="40"
                width="40"
                sizes="xs:40px sm:40px md:40px lg:40px xl:40px 2xl:40px"
                :src="item.image"
                :alt="item.name"
                :img-attrs="{
                    class: 'h-full w-full object-cover',
                }" />

            <span class="absolute inset-0 bg-gray-500/5"></span>
        </span>

        <span v-else-if="item.type === 'page'"
            class="relative mr-4 size-10 overflow-hidden rounded-full bg-gray-100">
            <v-icon icon="book"
                class="absolute left-1/2 top-1/2
                -translate-x-1/2 -translate-y-1/2
                text-3xl text-gray-500"></v-icon>
        </span>

        <span v-else
            class="mr-4 size-10 overflow-hidden rounded-full bg-gray-100"></span>

        <span>
            <slot></slot>
        </span>

        <v-icon v-if="isParent"
            icon="chevron-right"
            class="ml-auto text-xl"></v-icon>
    </component>
</template>

<script setup lang="ts">
import type { TreeItem } from '~/server/api/navigation.get';

const properties = defineProps<{
    item: TreeItem
}>();

const isParent = computed(() => properties.item.children && properties.item.children.length > 0);

const tag = computed(() => {
    if (!isParent.value) {
        return defineNuxtLink({});
    }

    return 'button';
});
</script>
