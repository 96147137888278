<template>
    <div class="flex flex-nowrap items-center overflow-hidden rounded border transition-colors duration-75"
        :class="{
            'h-8': size === 'sm',
            'h-14': size === 'md',
            'border-red-500 focus-within:border-red-600 hover:border-red-600': error,
            'border-gray-100 focus-within:border-gray-200 hover:border-gray-200': !error,
        }">
        <v-quantity-button @click="quantity -= step">
            <v-icon icon="minus" />
        </v-quantity-button>

        <div class="block h-full flex-1">
            <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
            <input :value="quantity"
                class="block h-full w-8 bg-transparent py-1 text-center outline-none"
                type="text"
                tabindex="-1"
                readonly>
        </div>

        <v-quantity-button @click="quantity += step">
            <v-icon icon="add" />
        </v-quantity-button>
    </div>
</template>

<script setup lang="ts">
const properties = withDefaults(defineProps<{
    modelValue: number
    size?: 'sm' | 'md'
    error?: boolean
    min?: number
    max?: number
    step?: number
}>(), {
    size: 'sm',
    min: undefined,
    max: undefined,
    step: 1,
});

const emit = defineEmits<{
    (event: 'update:modelValue', value: number): void
}>();

const quantity = computed({
    get() {
        return properties.modelValue;
    },
    set(newValue: number) {
        if (properties.step && newValue % properties.step !== 0) {
            newValue = Math.floor(newValue / properties.step) * properties.step;
        }

        if (properties.max && newValue > properties.max) {
            newValue = properties.max;
        }

        const min = properties.min || properties.step;

        if (newValue < min) {
            newValue = min;
        }

        emit('update:modelValue', newValue);
    },
});
</script>
