import {
    dinero,
    toDecimal,
} from 'dinero.js';
import { EUR } from '@dinero.js/currencies';

interface Options {
    maximumFractionDigits?: number
}

export function useCurrency() {
    return {
        format: (amount: number | string, options: Options = {}) => {
            const parsedAmount = typeof amount === 'string' ? Number.parseFloat(amount) : amount;

            const value = dinero({
                amount: Math.round(parsedAmount * 100),
                currency: EUR,
            });

            return toDecimal(value, ({ value: number }) => (
                Number(number).toLocaleString('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                    maximumFractionDigits: 0,
                    ...options,
                })
            ));
        },
    };
}
