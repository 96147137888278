/* eslint-disable @typescript-eslint/no-explicit-any */

export function useStoryblokTransformer() {
    const transform = (content: any): any => {
        if (Array.isArray(content)) {
            return content.map((item) => transform(item));
        }

        if (isObject(content)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((content as any)?.fieldtype === 'asset') {
                return useOmit(content, [
                    'fieldtype',
                    'focus',
                    'source',
                    'copyright',
                    'meta_data',
                    'content_type',
                    'is_external_url',
                    'is_private',
                ]);
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((content as any)?.fieldtype === 'multilink') {
                return useOmit(content, [
                    'id',
                    'fieldtype',
                    'linktype',
                ]);
            }

            return Object.fromEntries(
                Object.entries(content).map(([key, value]) => [
                    key,
                    transform(value),
                ]),
            );
        }

        return content;
    };

    return {
        transform,
    };
}
