import { joinURL } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';
import { encode } from 'js-base64';
import { createOperationsGenerator } from '#image/utils';

const operationsGenerator = createOperationsGenerator({
    keyMap: {
        resize: 'rs',
        size: 's',
        fit: 'rt',
        width: 'w',
        height: 'h',
        dpr: 'dpr',
        enlarge: 'el',
        extend: 'ex',
        gravity: 'g',
        crop: 'c',
        padding: 'pd',
        trim: 't',
        rotate: 'rot',
        quality: 'q',
        maxBytes: 'mb',
        background: 'bg',
        backgroundAlpha: 'bga',
        blur: 'bl',
        sharpen: 'sh',
        watermark: 'wm',
        preset: 'pr',
        cacheBuster: 'cb',
        stripMetadata: 'sm',
        stripColorProfile: 'scp',
        autoRotate: 'ar',
        filename: 'fn',
        format: 'f',
    },
    formatter: (key: string, value: string) => `${key}:${value}`,
});

export const getImage: ProviderGetImage = (
    source: string,
    {
        modifiers,
        baseURL,
    } = {},
) => {
    const mergedModifiers = Object.fromEntries(Object.entries({
        fit: 'fill',
        width: 0,
        height: 0,
        gravity: 'no',
        enlarge: 1,
        format: 'webp',
        ...modifiers,
    }).map(([modifier, value]) => [modifier, value?.toString()]));

    const encodedUrl = encode(source);
    const path = joinURL('/', operationsGenerator(mergedModifiers), encodedUrl);

    return {
        url: joinURL(baseURL, path),
    };
};
