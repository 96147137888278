<template>
    <div class="relative flex items-center">
        <div class="pointer-events-none absolute inset-y-0 left-sm flex items-center">
            <v-icon icon="tag"
                class="text-xl" />
        </div>

        <div class="flex-1">
            <v-input v-model="input"
                type="text"
                size="sm"
                :readonly="!!cart.promotionCode"
                :placeholder="t('type-code')"
                class="pl-lg pr-16 lg:pr-20" />
        </div>

        <div class="absolute inset-y-0 right-sm flex items-center space-x-4">
            <v-button v-if="input.length > 0 && !cart.promotionCode"
                size="sm"
                class="underline hover:text-red-500 focus:text-red-500 active:text-red-700"
                @click="save">
                {{ t('activate') }}
            </v-button>

            <template v-else-if="cart.promotionCode">
                <v-button size="sm"
                    class="px-0 underline hover:text-red-500 focus:text-red-500 active:text-red-700"
                    @click="clear">
                    {{ t('deactivate') }}
                </v-button>

                <v-icon icon="code-active"
                    class="text-2xl" />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMutation } from '@urql/vue';
import {
    CartUpdatePromotionCodeDocument,
    type CartUpdatePromotionCodeMutation,
    type CartUpdatePromotionCodeMutationVariables,
    type ViewCartQuery,
} from '~~/graphql';
import { useToast } from '~~/store/toast';

const properties = defineProps<{
    cart: Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>
}>();

const { t } = useI18n();
const toast = useToast();
const input = ref<string>(properties.cart.promotionCode?.code ?? '');

const {
    executeMutation: createMutation,
} = useMutation<CartUpdatePromotionCodeMutation, CartUpdatePromotionCodeMutationVariables>(
    CartUpdatePromotionCodeDocument,
);

async function save() {
    const response = await createMutation({
        id: properties.cart.id,
        promotionCode: input.value,
    });

    if (response.data?.cartUpdatePromotionCode.__typename === 'CartUpdatePayload'
        && response.data.cartUpdatePromotionCode.cart) {
        if (response.data.cartUpdatePromotionCode.cart.promotionCode == null) {
            toast.add('warning', `${t('toast.code-error')}`);
        }
        return;
    }

    toast.add('success', `${t('toast.code-activated')}`);
}

async function clear() {
    await createMutation({
        id: properties.cart.id,
        promotionCode: null,
    });

    input.value = '';

    toast.add('success', `${t('toast.code-deactivated')}`);
}
</script>

<i18n lang="json">
{
    "et": {
        "type-code": "Sisestage sooduskood",
        "activate": "Rakenda",
        "deactivate": "Lähtesta"
    },
    "fi": {
        "type-code": "Syötä kampanjakoodi",
        "activate": "Aktivoi",
        "deactivate": "Nollaa"
    }
}
</i18n>
