<template>
    <div class="flex w-screen rounded-none shadow-xl sm:w-[300px] sm:rounded-sm">
        <div class="absolute right-[10px] hidden w-4 border-x-8 border-b-8 border-x-transparent
            border-b-white sm:block"
            style="top: -7px;">
        </div>

        <button type="button"
            class="absolute right-0 p-4 text-right outline-none"
            @click="$emit('close')">
            <v-icon icon="close"
                class="text-xl" />
        </button>

        <div class="flex flex-col p-4 text-left">
            <div class="flex flex-row items-center">
                <div class="mr-2 flex size-4 items-center justify-center rounded-full bg-green-500 text-white">
                    <span class="text-xs">✓</span>
                </div>

                <p class="font-bold text-black">
                    {{ t('toast.wishlist-success') }}
                </p>
            </div>

            <div v-if="wishListProduct"
                class="py-sm lg:py-lg">
                <v-header-wishlist-product-small :wish-list-product="wishListProduct" />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ViewCartQuery } from '~~/graphql';

type Cart = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>;

const properties = defineProps<{
    cart?: Cart
    productId?: string
}>();

defineEmits<{
    (event: 'close'): void
}>();

const { t } = useI18n();

const wishListProduct = computed(() => (
    properties.cart?.products.find((item) => item.product.id === properties.productId)
));
</script>
