<template>
    <v-modal :is-open="isOpen"
        class="bg-white"
        @close="$emit('close')">
        <v-modal-header @close="$emit('close')">
            {{ t('login') }}
        </v-modal-header>

        <div class="my-md h-max bg-gray-50 p-0">
            <v-login-ingress></v-login-ingress>

            <div class="px-sm md:px-md">
                <v-button theme="black"
                    class="my-sm w-full px-sm md:px-md"
                    :aria-label="t('login')"
                    @click="logIn">
                    {{ t('login') }}
                </v-button>
            </div>
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
defineProps<{
    isOpen: boolean
}>();
defineEmits<{
    (event: 'close'): void
}>();
const { t } = useI18n();
const auth = useAuth();

async function logIn() {
    await auth.login(window.location.pathname);
}
</script>
