<template>
    <div>
        <v-header-button :aria-label="isLoggedIn ? t('aria.open', { name: t('profile-page-url')}) : t('login')"
            :to="isLoggedIn ? '/account' : undefined"
            class="rounded p-1 transition-colors duration-150
            focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
            focus-visible:ring-4 focus-visible:ring-blue-600"
            @click="onClick">
            <v-icon icon="user"
                class="text-xl text-[color:var(--color)]"
                :style="{
                    '--color': fontColor
                }" />

            <span v-if="isLoggedIn"
                class="absolute bottom-1 right-0 size-4 items-center justify-center rounded-full bg-black
                    text-center text-xs leading-4 text-white">
                <v-icon icon="check" />
            </span>
        </v-header-button>

        <v-header-profile :is-open="isProfileOpen"
            @close="isProfileOpen = false" />
    </div>
</template>

<script lang="ts" setup>
const properties = defineProps<{
    fontColor?: string
}>();

const { t } = useI18n();
const isProfileOpen = ref(false);
const { profile, token } = useCustomerLogin();

const isLoggedIn = computed(() => !!token.value && !!profile.profile);

function onClick() {
    if (!isLoggedIn.value) {
        isProfileOpen.value = true;
    }
}

const fontColor = computed<string | undefined>(() => (properties.fontColor === undefined
    ? '#FFF'
    : properties.fontColor));
</script>
