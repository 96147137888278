import { defineNuxtPlugin } from '#app';

/*
    For tranlated version, add locale parameter to this function.
    toLocaleDateString(param)
*/

export default defineNuxtPlugin(() => ({
    provide: {
        localDate: ((date: string | Date) => new Date(date).toLocaleDateString('fi-FI')),
        localTime: ((date: string | Date) => new Date(date)
            .toLocaleTimeString(['fi-FI'], { hour: '2-digit', minute: '2-digit' })),

    },
}));
