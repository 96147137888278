<template>
    <div class="relative">
        <button type="button"
            class="absolute right-xs top-xs z-20"
            @click.stop="remove">
            <v-icon icon="close"
                class="text-xl" />
        </button>

        <div class="grid grid-cols-3 space-x-sm">
            <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${product.product.seo.slug}`)"
                :aria-label="product.product.name"
                class="relative flex size-32 items-center justify-center overflow-hidden rounded p-2">
                <div class="absolute inset-0 rounded bg-gray-500/5"></div>

                <nuxt-picture v-if="product.product.listingImage"
                    :src="product.product.listingImage.url"
                    height="112"
                    loading="lazy"
                    width="112"
                    class="size-full"
                    :class="{
                        'opacity-50': !product.product.inventory.isAvailableForPurchase
                    }"
                    sizes="sm:100vw md:555px lg:725px xl:896px 2xl:1067px"
                    :alt="product.product.name"
                    :img-attrs="{
                        class: 'h-full w-full object-contain',
                    }" />
            </nuxt-link>

            <div class="col-span-2 flex flex-1 flex-col">
                <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${product.product.seo.slug}`)">
                    <div class="mb-4 flex w-full items-center justify-between">
                        <div class="w-full pr-lg">
                            <v-subtitle class="break-words font-bold">
                                {{ product.product.title || product.product.name }}
                            </v-subtitle>

                            <p v-if="product.product.specification"
                                class="text-gray-500">
                                {{ product.product.specification }}
                            </p>
                        </div>
                    </div>

                    <div class="mb-4 flex items-center space-x-4">
                        <div class="flex items-baseline space-x-2">
                            <template v-if="isClubPromotionActive && isLoggedIn && clubPrice">
                                <span class="font-prenton-cond text-xl">
                                    {{ formatCurrency(
                                        clubPrice,
                                        { maximumFractionDigits: 2 },
                                    ) }}
                                </span>

                                <del
                                    v-if="product.product.pricing.price
                                        && clubPrice !== Number(product.product.pricing.price.amount)"
                                    class="text-xs text-gray-500 line-through">
                                    {{ formatCurrency(
                                        product.product.pricing.price.amount,
                                        { maximumFractionDigits: 2 },
                                    ) }}
                                </del>
                            </template>

                            <template v-else-if="product.product.pricing.price">
                                <span class="font-prenton-cond text-xl">
                                    {{ formatCurrency(
                                        product.product.pricing.price.amount,
                                        { maximumFractionDigits: 2 },
                                    ) }}
                                </span>

                                <del
                                    v-if="product.product.pricing.originalPrice
                                        && product.product.pricing.price.amount
                                            !== product.product.pricing.originalPrice?.amount"
                                    class="text-xs text-gray-500 line-through">
                                    {{ formatCurrency(
                                        product.product.pricing.originalPrice.amount,
                                        { maximumFractionDigits: 2 },
                                    ) }}
                                </del>
                            </template>
                        </div>
                    </div>
                </nuxt-link>

                <div>
                    <v-button v-if="product.product.inventory.isAvailableForPurchase"
                        class="w-full"
                        theme="black"
                        size="sm"
                        @click="purchase">
                        {{ t('add-to-cart') }}
                    </v-button>

                    <v-button v-else
                        class="w-full cursor-not-allowed"
                        theme="gray"
                        size="sm"
                        disabled>
                        {{ t('out-of-stock') }}
                    </v-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { type ViewCartQuery } from '~~/graphql';
import { useProfile } from '~~/store/profile';

const properties = defineProps<{
    product: Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0]
}>();

const cart = useCart();

const {
    isPromotionActive: isClubPromotionActive,
    clubPrice,
} = useClubPromotion(computed(() => properties.product.product), 'wishlist');

const { format: formatSbUrl } = useSbUrl();
const { format: formatCurrency } = useCurrency();

const { t } = useI18n();

const profileStore = useProfile();

const isLoggedIn = !!profileStore.profile?.id;

/** Purchace logic */
async function purchase() {
    await cart.addProduct(properties.product.product.id);
}

/** Remove product from cart */
async function remove() {
    await cart.removeProduct(properties.product.id);
}
</script>

<i18n lang="json">
{
    "et": {
        "add-to-cart": "Lisa ostukorvi",
        "out-of-stock": "Hetkel laost otsas"
    },
    "fi": {
        "add-to-cart": "Lisää ostoskoriin",
        "out-of-stock": "Tuote loppunut"
    }
}
</i18n>
