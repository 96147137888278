import { defineNuxtPlugin } from '#app';

/*
    Plugin for checking Finnish social security number.
    This plugin is primarily used in checkout section of the page.
    checkSSN(param)
*/

export default defineNuxtPlugin(() => ({
    provide: {
        checkSSN(ssn: string): boolean {
            const pattern = /^\d{6}[\f+a-]\d{3}[\da-z]$/i;
            const result = pattern.test(ssn.toString());

            return result;
        },
    },
}));
