import {
    type SimpleProductDataFragment,
    type ViewCartQuery,
    type CartDataFragment,
    type SimpleProduct,
} from '~~/graphql';

type CartProduct = CartDataFragment['products'][0]['product'];

type CartRelatedProduct = Extract<
    Extract<
        ViewCartQuery['cart'],
        { __typename: 'Cart' }
    >['products'][0]['product']['relatedProducts']['edges'][0]['node'],
    { __typename: 'SimpleProduct' }
>;

type CartRecommendedProduct = Extract<
    Extract<
        ViewCartQuery['cart'],
        { __typename: 'Cart' }
    >['recommendedProducts'][0],
    { __typename: 'SimpleProduct' }
>;

type InputProduct =
    | SimpleProductDataFragment
    | SimpleProduct
    | CartProduct
    | CartRelatedProduct
    | CartRecommendedProduct;

interface GaProduct {
    /* eslint-disable @typescript-eslint/naming-convention */
    item_id?: string
    item_name: string
    item_unique_id: string
    item_brand?: string
    price: number
    quantity: number
    /* eslint-enable @typescript-eslint/naming-convention */
}

export function useGtmValues() {
    const {
        public: {
            appUrl,
        },
    } = useRuntimeConfig();

    const cart = useCart();

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const productToGa = (product: InputProduct, quantity: number): GaProduct => ({
        /* eslint-disable @typescript-eslint/naming-convention */
        item_id: product?.sku ?? undefined,
        item_name: product.name,
        item_unique_id: product.id,
        ...product.brand ? { item_brand: product.brand.title } : {},
        price: Number.parseFloat(product.pricing.price?.amount || '0'),
        quantity,
        /* eslint-enable @typescript-eslint/naming-convention */
    });

    const cartValues = computed(() => ({
        /* eslint-disable @typescript-eslint/naming-convention */
        cart_ref: cart.id.value || '',
        cart_url: cart.id.value ? `${appUrl}/cart/${cart.id.value}/switch` : '',
        /* eslint-enable @typescript-eslint/naming-convention */
    }));

    return {
        cart: cartValues,
        productToGa,
    };
}
