<template>
    <div class="flex space-x-sm">
        <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${wishListProduct.product.seo.slug}`)"
            :aria-label="wishListProduct.product.name"
            class="relative flex size-32 items-center justify-center overflow-hidden rounded p-2">
            <div class="absolute inset-0 rounded bg-gray-500/5"></div>

            <nuxt-picture v-if="wishListProduct.product.listingImage"
                :src="wishListProduct.product.listingImage.url"
                height="112"
                loading="lazy"
                width="112"
                class="size-full"
                sizes="xs:100vw sm:112px md:224px lg:448px xl:896px 2xl:896px"
                :alt="wishListProduct.product.name"
                :img-attrs="{
                    class: 'h-full w-full object-contain',
                }" />
        </nuxt-link>

        <div class="flex-1">
            <div class="mb-4 flex items-center justify-between">
                <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${wishListProduct.product.seo.slug}`)"
                    :aria-label="wishListProduct.product.name"
                    class="group">
                    <v-subtitle class="font-bold text-black group-hover:underline">
                        {{ wishListProduct.product.title || wishListProduct.product.name }}
                    </v-subtitle>

                    <p v-if="wishListProduct.product.specification"
                        class="text-gray-500">
                        {{ wishListProduct.product.specification }}
                    </p>
                </nuxt-link>
            </div>

            <div class="flex items-center space-x-4">
                <div class="flex items-baseline space-x-2">
                    <span class="font-prenton-cond text-xl text-red-500">
                        {{ formatCurrency(wishListProduct.unitPrice.amount, { maximumFractionDigits: 2 }) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ViewCartQuery } from '~~/graphql';

type Cart = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>;
type CartProduct = Cart['products'][0];

defineProps<{
    wishListProduct: CartProduct
}>();

const { format: formatSbUrl } = useSbUrl();
const { format: formatCurrency } = useCurrency();
</script>
