<template>
    <div class="mx-md">
        <nuxt-picture v-if="ingressImage"
            :src="ingressImage"
            :alt="ingressImageAlt"
            height="128"
            loading="lazy"
            sizes="xs:100vw sm:254px md:254px lg:254px xl:254px 2xl:254px">
        </nuxt-picture>

        <div v-if="ingress">
            <v-storyblok-rich-text :document="ingress" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ISbRichtext } from '@storyblok/js';

const contents = useStoryblokStory('global/login-ingress');

const ingressImage = computed<string | undefined>(() => (
    contents.value?.content.logo.filename
));

const ingressImageAlt = computed<string | undefined>(() => (
    contents.value?.content.logo.alt
));

const ingress = computed<ISbRichtext | undefined>(() => (
    contents.value?.content.ingress.type === 'doc' ? contents.value.content.ingress as ISbRichtext : undefined
));
</script>
