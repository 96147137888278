<template>
    <slot :select="addItemClick" />
</template>

<script setup lang="ts">
import type { Product } from '@apptus/esales-api';
import { useGtm } from '@gtm-support/vue-gtm';

const properties = defineProps<{
    product: Product
}>();

const gtm = useGtm();

const { getStringValues } = useElevateProduct(properties.product);

const sku = computed(() => getStringValues('sku')[0]);
const productId = computed(() => getStringValues('id')[0]);

const gaProduct = computed(() => ({
    /* eslint-disable @typescript-eslint/naming-convention */
    item_id: sku.value,
    item_name: properties.product.title,
    item_unique_id: productId.value,
    ...properties.product.brand ? { item_brand: properties.product.brand } : {},
    price: properties.product.sellingPrice.min,
    quantity: 1,
    /* eslint-enable @typescript-eslint/naming-convention */
}));

function addItemClick(): void {
    gtm?.trackEvent({
        /* eslint-disable @typescript-eslint/naming-convention */
        event: 'select_item',
        _clear: true,
        ecommerce: {
            items: [gaProduct.value],
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    });
}
</script>
