// This allows users to input invisible hyphenation guides (soft hyphens) by inserting "--" in text fields of some storyblok components that support it. The hyphenation guides make the browser hyphenate words that would otherwise not fit.
export function useHyphenation() {
    return {
        hyphenate: (content: string) => (
            content.length > 0
                ? content.replaceAll('--', '\u00AD')
                : ''
        ),
    };
}
