export default defineNuxtRouteMiddleware(({ path, query, hash }) => {
    if (
        !path.endsWith('/')
        || [
            '/',
            '/graphql/',
        ].includes(path)
    ) {
        return;
    }

    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };

    // 308 Permanent Redirect
    // eslint-disable-next-line consistent-return
    return navigateTo(nextRoute, { redirectCode: 308 });
});
