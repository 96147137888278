<template>
    <component :is="iconComponent"
        class="inline-block size-[1em] align-[-.125em]"
        role="img"
        aria-hidden="true"
        focusable="false" />
</template>

<script lang="ts" setup>
import type { Icon } from '~~/types';

const properties = defineProps<{
    icon: Icon
}>();

const empty = defineComponent({
    render() {
        return h('span');
    },
});

const loadedIcons = new Map<Icon, Component>();

const loadIconComponent = (icon: Icon, previousIcon?: Component) => {
    if (loadedIcons.has(icon)) {
        return loadedIcons.get(icon);
    }

    const asyncComponent = markRaw(defineAsyncComponent({
        loader: async () => {
            try {
                const module = await import(`../assets/icons/${icon}.svg?component`);
                const component = markRaw(module.default);

                loadedIcons.set(icon, component);

                return component;
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error(`Failed to load icon: ${icon}`, error);

                return previousIcon || empty;
            }
        },
        loadingComponent: previousIcon || empty,
        delay: 0,
    }));

    return asyncComponent;
};

const iconComponent = shallowRef(loadIconComponent(properties.icon));

watch(() => properties.icon, (newIcon) => {
    const previousIcon = iconComponent.value;

    iconComponent.value = loadIconComponent(newIcon, previousIcon);
});
</script>
