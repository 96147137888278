<template>
    <div class="flex space-x-sm">
        <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${cartProduct.product.seo.slug}`)"
            :aria-label="cartProduct.product.name"
            class="relative flex size-32 items-center justify-center overflow-hidden rounded p-2">
            <div class="absolute inset-0 rounded bg-gray-500/5"></div>

            <nuxt-picture v-if="cartProduct.product.listingImage"
                :src="cartProduct.product.listingImage.url"
                height="112"
                loading="lazy"
                width="112"
                class="size-full"
                sizes="xs:100vw sm:112px md:224px lg:448px xl:896px 2xl:896px"
                :alt="cartProduct.product.name"
                :img-attrs="{
                    class: 'h-full w-full object-contain',
                }" />
        </nuxt-link>

        <div class="flex-1">
            <div class="mb-4 flex items-center justify-between">
                <nuxt-link :to="formatSbUrl(`/${$t('storyblok.product')}/${cartProduct.product.seo.slug}`)"
                    :aria-label="cartProduct.product.name"
                    class="group">
                    <v-subtitle class="font-bold text-black group-hover:underline">
                        {{ cartProduct.product.title || cartProduct.product.name }}
                    </v-subtitle>

                    <p v-if="cartProduct.product.specification"
                        class="line-clamp-2 text-gray-500">
                        {{ cartProduct.product.specification }}
                    </p>
                </nuxt-link>
            </div>

            <div v-if="isClubPromotionActive && clubPrice !== undefined"
                class="flex items-center space-x-4 space-y-md">
                <div class="flex flex-col items-baseline gap-2">
                    <span class="font-prenton-cond text-xl text-red-500">
                        {{ formatCurrency(clubPrice, { maximumFractionDigits: 2 }) }}
                    </span>

                    <div class="rounded-2xs border border-red-500 bg-red-500
                        p-2xs font-prenton-cond uppercase text-white">
                        <p>{{ t('club-price') }}</p>
                    </div>
                </div>
            </div>

            <div v-else
                class="flex items-center space-x-4">
                <div class="flex items-baseline space-x-2">
                    <span class="font-prenton-cond text-xl text-red-500">
                        {{ formatCurrency(cartProduct.unitPrice.amount, { maximumFractionDigits: 2 }) }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { ViewCartQuery } from '~~/graphql';

type Cart = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>;
type CartProduct = Cart['products'][0];

const properties = defineProps<{
    cartProduct: CartProduct
}>();

const {
    isPromotionActive: isClubPromotionActive,
    clubPrice,
} = useClubPromotion(computed(() => properties.cartProduct.product), 'cart-notification');

const { format: formatSbUrl } = useSbUrl();
const { format: formatCurrency } = useCurrency();

const { t } = useI18n();
</script>

<i18n lang="json">
{
    "et": {
        "club-price": "Klubihind"
    },
    "fi": {
        "club-price": "Klubihinta"
    }
}
</i18n>
