export interface StoryblokLink {
    /* eslint-disable @typescript-eslint/naming-convention */
    id: number
    slug: string
    name: string
    is_folder: boolean
    uuid: string
    real_path: string
    /* eslint-enable @typescript-eslint/naming-convention */
}

export function useStoryblokLinks() {
    const api = useStoryblokApi();

    return useAsyncData<StoryblokLink[]>(
        'storyblok-links',
        () => api.getAll('cdn/links', {
            version: useRuntimeConfig().public.storyblokVersion === 'published' ? 'published' : 'draft',
        }),
        {
            transform: (response) => response.map((story) => usePick(story, [
                'name',
                'id',
                'uuid',
                'slug',
                'full_slug',
                'is_folder',
                'real_path',
            ])) as unknown as StoryblokLink[],
        },
    );
}
