import { kebabCase } from 'case-anything';

export default defineNuxtPlugin(() => ({
    provide: {
        slugify(input: string): string {
            return kebabCase(
                input
                    .replaceAll('ä', 'a')
                    .replaceAll('å', 'a')
                    .replaceAll('ö', 'o')
                    .replaceAll('Ä', 'A')
                    .replaceAll('Å', 'A')
                    .replaceAll('Ö', 'O'),
            );
        },
    },
}));
