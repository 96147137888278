import type { Product } from '@apptus/esales-api';

type ValueType = 'string' | 'number' | 'boolean';
type ReturnValueType<T extends ValueType> = T extends 'string'
    ? string
    : (T extends 'number'
        ? number
        : (T extends 'boolean'
            ? boolean
            : never
        )
    );

interface GetValues {
    <T extends ValueType>(attribute: string, type: T): ReturnValueType<T>[]
}

interface GetTypedValue {
    (attribute: string, type: 'lengths'): number | undefined
    (attribute: string, type: 'json'): unknown
    (attribute: string, type: 'numbers'): number | undefined
}

export function useElevateProduct(product: Product) {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    const getCastValue = <T extends ValueType>(value: string, type: T): ReturnValueType<T> => {
        if (type === 'number') {
            return Number.parseFloat(value) as ReturnValueType<T>;
        }

        if (type === 'boolean') {
            return (value === 'true') as ReturnValueType<T>;
        }

        if (type === 'string') {
            return value as ReturnValueType<T>;
        }

        throw new Error('Unknown type');
    };

    const getValues: GetValues = (attribute, type) => (
        product.custom?.[attribute]?.map((value) => getCastValue(value.label, type)) || []
    );

    const getTypedValue: GetTypedValue = (attribute, type) => (
        // wrong types
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (product.typedCustom as any)?.[type][attribute]
    );

    const getStringValues = (attribute: string) => getValues(attribute, 'string');
    const getNumberValues = (attribute: string) => getValues(attribute, 'number');
    const getBooleanValues = (attribute: string) => getValues(attribute, 'boolean');
    const getTypedNumberValue = (attribute: string) => getTypedValue(attribute, 'numbers');

    const getValueIds = (attribute: string) => product.custom?.[attribute]?.map((value) => value.id) || [];

    return {
        getValues,
        getStringValues,
        getNumberValues,
        getBooleanValues,
        getValueIds,
        getTypedNumberValue,
    };
}
