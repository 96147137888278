<template>
    <div class="relative flex flex-col items-center space-y-sm bg-yellow-200 p-sm md:rounded-xs"
        :class="{
            'lg:flex-row lg:items-center lg:justify-between lg:space-y-0': !vertical,
        }">
        <div class="flex items-center">
            <v-icon icon="masku-klubi-2024"
                class="shrink-0 text-9xl" />

            <p class="px-sm">
                <template v-if="clubSavings === 0">
                    {{ t('club-notification-without-discount') }}
                </template>

                <template v-else>
                    {{ t('club-notification') }}
                    <b>{{ t('club-notification-bolded',
                            { amount: formatCurrency(clubSavings, { maximumFractionDigits: 2} )})
                    }}</b>
                </template>
            </p>
        </div>

        <v-page-cart-login-button />
    </div>
</template>

<script setup lang="ts">
import {
    type ViewCartQuery,
} from '~~/graphql';

type Product = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0];

const properties = defineProps<{
    products?: Product[]
    totalPrice: string
    vertical?: boolean
}>();

const { t } = useI18n();
const { format: formatCurrency } = useCurrency();

const clubSavings = computed(() => (
    (properties.products || [])
        .map((product) => {
            const { discount } = useClubPromotion(toRef(product.product), 'cart');

            return discount.value ? discount.value * product.quantity : 0;
        })
        .reduce((accumulator, price) => accumulator + price, 0)
));
</script>

<i18n lang="json">
{
    "et": {
        "club-notification-without-discount": "Ära unusta sisse logida!",
        "club-notification": "Ära unusta sisse logida! Klubiliikmena",
        "club-notification-bolded": "säästad ostukorvis {amount}."
    },
    "fi": {
        "club-notification-without-discount": "Muista kirjautua!",
        "club-notification": "Muista kirjautua! Klubilaisena",
        "club-notification-bolded": "säästät ostoskoristasi {amount}."
    }
}
</i18n>
