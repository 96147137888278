<template>
    <div class="w-screen rounded-none shadow-xl sm:w-auto sm:rounded-sm">
        <div class="absolute right-[10px] hidden w-4 border-x-8 border-b-8 border-x-transparent
            border-b-white sm:block"
            style="top: -7px;">
        </div>

        <button type="button"
            class="absolute right-0 p-4 text-right outline-none"
            @click="$emit('close')">
            <v-icon icon="close"
                class="text-xl" />
        </button>

        <div class="flex flex-col p-4 text-left">
            <div class="flex flex-row items-center">
                <div class="mr-2 flex size-4 items-center justify-center rounded-full bg-green-500 text-white">
                    <span class="text-xs">✓</span>
                </div>

                <p class="font-bold text-black">
                    {{ $t('toast.cart-success') }}
                </p>
            </div>

            <div v-if="cartProduct"
                class="py-sm lg:py-lg">
                <v-header-cart-product-small :cart-product="cartProduct" />
            </div>

            <div class="flex flex-row gap-2">
                <v-button class="flex-1 text-center"
                    theme="gray"
                    size="md"
                    to="/cart"
                    @click="$emit('close')">
                    {{ $t('to-cart') }}
                </v-button>

                <v-button class="flex-1 text-center"
                    :theme="checkoutButtonTheme"
                    size="md"
                    :class="{
                        'cursor-not-allowed': !isBuyable,
                    }"
                    :disabled="!isBuyable"
                    @click="createOrder()">
                    {{ t('go-to-checkout') }}
                </v-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useMutation } from '@urql/vue';
import { useSessionStorage } from '@vueuse/core';
import {
    OrderCreateFromCartDocument,
    type OrderCreateFromCartMutation,
    type OrderCreateFromCartMutationVariables,
    type ViewCartQuery,
    ProductState,
} from '~~/graphql';

type Cart = Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>;

const properties = defineProps<{
    cart?: Cart
    productId?: string
}>();
defineEmits<{
    (event: 'close'): void
}>();
const router = useRouter();
const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });

const checkoutButtonTheme = computed<'black' | 'checkout'>(() => (
    tg('elements.checkout-button') === 'black' ? 'black' : 'checkout'
));

const cartProduct = computed(() => (
    properties.cart?.products.find((item) => item.product.id === properties.productId)
));

const isBuyable = computed(() => (
    properties.cart?.products.every((product) => (
        product.product.inventory.isAvailableForPurchase
        && product.product.state !== ProductState.Draft
    )) ?? false
));

const {
    executeMutation: executeCreateOrder,
} = useMutation<OrderCreateFromCartMutation, OrderCreateFromCartMutationVariables>(
    OrderCreateFromCartDocument,
);

async function createOrder() {
    const cartId = useCookie('cart').value;

    if (!cartId) {
        return;
    }

    const response = await executeCreateOrder({
        cartId,
    });

    if (response.data?.orderCreateFromCart.__typename !== 'OrderCreatePayload') {
        return;
    }

    const orderCookie = useCookie('order');
    const orderPaymentData = useSessionStorage('order-payment-data', {});

    orderCookie.value = response.data.orderCreateFromCart.order.id;
    orderPaymentData.value = undefined;

    router.push({
        name: 'checkout',
    });
}
</script>

<i18n lang="json">
{
    "et": {
        "cart-modal-title": "Ostukorv",
        "cart-modal-empty": "Ostukorv on tühi",
        "go-to-checkout": "Suundu maksma"
    },
    "fi": {
        "cart-modal-title": "Ostoskori",
        "cart-modal-empty": "Ostoskori on tyhjä",
        "go-to-checkout": "Siirry kassalle"
    }
}
</i18n>
