<template>
    <h-transition-root as="template"
        :show="isOpen">
        <h-dialog class="fixed inset-0 z-30"
            @close="$emit('close')">
            <div class="h-screen">
                <h-transition-child as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-150 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0">
                    <h-dialog-overlay class="fixed inset-0 bg-black/30" />
                </h-transition-child>

                <h-transition-child as="template"
                    enter="transition ease-out duration-300"
                    :enter-from="`opacity-0 ${top ? '-translate-y-full' : 'translate-x-full'}`"
                    :enter-to="`opacity-100 ${top ? 'translate-y-0' : 'translate-x-0'}`"
                    leave="transition ease-in duration-150"
                    :leave-from="`opacity-100 ${top ? 'translate-y-0' : 'translate-x-0'}`"
                    :leave-to="`opacity-0 ${top ? '-translate-y-full' : 'translate-x-full'}`">
                    <div v-bind="$attrs"
                        class="fixed overflow-auto"
                        :class="{
                            'inset-x-0 top-0': top,
                            'inset-y-0 right-0 w-screen lg:w-[450px]': !top && !fullScreen,
                            'lg:w-[760px]': type === 'reviews',
                            'inset-y-0 right-0 w-screen': fullScreen && !top,
                        }">
                        <slot></slot>
                    </div>
                </h-transition-child>
            </div>
        </h-dialog>
    </h-transition-root>
</template>

<script lang="ts" setup>
defineProps<{
    isOpen: boolean
    fullScreen?: boolean
    top?: boolean
    type?: string
}>();

defineEmits<{
    (event: 'close'): void
}>();
</script>

<script lang="ts">
export default {
    inheritAttrs: false,
};
</script>
