import { acceptHMRUpdate, defineStore } from 'pinia';
import type { ViewMeQuery } from '~/graphql';

interface State {
    profile?: NonNullable<ViewMeQuery['me']>
}

export const useProfile = defineStore('profile', {
    state: (): State => ({
        profile: undefined,
    }),

    actions: {
        setProfile(profile: NonNullable<ViewMeQuery['me']>) {
            this.profile = profile;
        },
        flushProfile() {
            this.profile = undefined;
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useProfile, import.meta.hot));
}
