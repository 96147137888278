import type {
    Autocomplete,
    SearchPage,
    LandingPage,
    ProductPage,
} from '@apptus/esales-api';
import { useWindowSize } from '@vueuse/core';
import {
    addDays,
    addHours,
    addMinutes,
    addYears,
    formatISO,
    isAfter,
    parseISO,
    startOfDay,
    startOfHour,
} from 'date-fns';
import { nanoid } from 'nanoid';
import { useProfile } from '~~/store/profile';

export interface RecommendationListSettings {
    id: string
    algorithm?:
        | 'TOP_PRODUCTS'
        | 'PERSONAL'
        | 'ALTERNATIVES'
        | 'UPSELL'
        | 'CART'
        | 'NEWEST_PRODUCTS'
        | 'STYLE_WITH'
        | 'FAVORITES'
        | 'MORE_FROM_SERIES'
        | 'RECENTLY_VIEWED'
    label?: string
    limit?: number
    params?: unknown
    productRules?: string
    showMoreLink?: string
    visualization?: 'CAROUSEL' | 'GRID'
}

interface ElevateSession {
    key: string
    expires: string
}

export function useElevate() {
    const { width } = useWindowSize();
    const profileStore = useProfile();
    const { categories } = useCookieFirst();
    const isTrackingAllowed = computed(() => categories.value?.advertising);
    const sessionCookie = useCookie<ElevateSession | undefined>('elevate-session', {
        expires: addYears(new Date(), 1),
    });
    const previewCookie = useCookie<'yes' | 'no' | undefined>('elevate-preview');

    const rangeKeys = [
        'price',
        'width',
        'height',
        'depth',
        'weight',
    ];

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const convertFilters = (filters: Record<string, string[]>): Record<string, string> => {
        const result: Record<string, string> = {};

        for (const [key, values] of Object.entries(filters)) {
            if (values.length === 0) {
                // eslint-disable-next-line no-continue
                continue;
            }

            if (rangeKeys.includes(key)) {
                [result[`f.${key}.min`], result[`f.${key}.max`]] = values;
            } else {
                result[`f.${key}`] = values.join('|');
            }
        }

        return result;
    };

    const {
        public: {
            elevate: {
                clusterId,
                locale,
                market,
            },
        },
    } = useRuntimeConfig();

    const customAttributeList = [
        'id',
        'sku',
        'title',
        'type',
        'specification',
        'attributeBadges',
        'promotion',
        'delivery_estimate_days',
        'clubPrice',
        'clubPriceDiscount',
        'productBadges',
        'variant.clubPrice',
        'variant.clubPriceDiscount',
        'rating',
        'categories',
        'facet_categories',
    ];

    const baseUrl = `https://${clusterId}.api.esales.apptus.cloud/api/storefront/v3/`;

    const isPreviewEnabled = computed(() => previewCookie.value === 'yes');

    const setPreviewState = (value: boolean) => {
        previewCookie.value = value ? 'yes' : 'no';
    };

    const getExpirationTime = (isNotification: boolean) => {
        // https://docs.elevate.voyado.cloud/elevate/4/integration/behavioral-data/visitor-identification/#sessions

        const dates: Date[] = [];

        if (isNotification) {
            // hourly expiration
            dates.push(startOfHour(addHours(new Date(), 2)));

            // UTC midnight expiration
            const midnightExpiry = addDays(startOfDay(new Date()), 2);
            midnightExpiry.setUTCHours(0, 0, 0, 0);
            dates.push(midnightExpiry);
        } else {
            // 15 minute inactivity timeout
            dates.push(addMinutes(new Date(), 15));
        }

        return new Date(Math.min(...dates.map((date) => date.getTime())));
    };

    const getSessionKey = (isNotification = false) => {
        const newExpiration = getExpirationTime(isNotification);

        // Generate new session key if session data is missing
        if (!sessionCookie.value) {
            sessionCookie.value = {
                key: nanoid(),
                expires: formatISO(newExpiration),
            };
        }

        const currentExpiration = parseISO(sessionCookie.value!.expires);

        // Generate new session key if current session key is expired
        if (isAfter(new Date(), currentExpiration)) {
            sessionCookie.value = {
                key: nanoid(),
                expires: formatISO(newExpiration),
            };
        }

        // Update expiration time if new expiration is later than current expiration
        if (isAfter(newExpiration, currentExpiration)) {
            sessionCookie.value = {
                ...sessionCookie.value,
                expires: formatISO(newExpiration),
            };
        }

        return sessionCookie.value!.key;
    };

    async function doGet<T>(
        url: string,
        parameters: Record<string, unknown>,
    ) {
        const sessionKey = getSessionKey();

        return useFetch<T>(baseUrl + url, {
            params: {
                customerKey: profileStore.profile?.id || sessionKey,
                market,
                sessionKey,
                locale,
                touchpoint: width.value >= 1024 ? 'DESKTOP' : 'MOBILE',
                viewId: previewCookie.value === 'yes' ? 'preview' : undefined,
                ...parameters,
            },
        });
    }

    async function doPost<T>(
        url: string,
        data: Record<string, unknown>,
        parameters: Record<string, unknown> = {},
        isNotification: boolean = false,
    ) {
        const sessionKey = getSessionKey(isNotification);

        return $fetch<T>(baseUrl + url, {
            method: 'POST',
            params: {
                customerKey: profileStore.profile?.id || sessionKey,
                market,
                sessionKey,
                locale,
                touchpoint: width.value >= 1024 ? 'DESKTOP' : 'MOBILE',
                viewId: previewCookie.value === 'yes' ? 'preview' : undefined,
                ...parameters,
            },
            body: data,
        });
    }

    const getAutocompleteSuggestions = async (
        query: string,
    ) => doGet<Autocomplete>('queries/autocomplete', {
        q: query,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
    });

    const getSearchResults = async (
        query: string,
        origin?: string,
        skip?: number,
        limit?: number,
        sort?: string,
        filters: Record<string, string[]> = {},
    ) => doGet<SearchPage>('queries/search-page', {
        q: query,
        origin,
        skip,
        limit,
        sort,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
        ...convertFilters(filters),
    });

    const getLandingPage = async (
        reference: string,
        skip?: number,
        limit?: number,
        sort?: string,
        filters: Record<string, string[]> = {},
    ) => doGet<LandingPage>('queries/landing-page', {
        pageReference: reference,
        skip,
        limit,
        sort,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
        ...convertFilters(filters),
    });

    const getProductPage = async (
        productKey: string,
        recommendationLists: RecommendationListSettings[],
    ) => doPost<ProductPage>('queries/product-page', {
        recommendationLists,
    }, {
        productKey,
        notify: isTrackingAllowed.value,
        presentCustom: customAttributeList.join('|'),
    });

    const notifyClick = async (
        ticket: string,
    ) => {
        if (!isTrackingAllowed) {
            return;
        }

        await doPost<never>('notifications/click', {
            ticket,
        }, {}, true);
    };

    return {
        getAutocompleteSuggestions,
        getSearchResults,
        getLandingPage,
        getProductPage,
        notifyClick,
        getSessionKey,
        getExpirationTime,
        setPreviewState,
        isPreviewEnabled,
    };
}
