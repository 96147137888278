/* eslint-disable unicorn/consistent-function-scoping */
import { get } from '@vueuse/core';
import type { Icon } from '~/types';

export function useReviews() {
    const { csrf } = useCsrf();

    const getRating = (skus: string[]) => {
        const { data, status } = useFetch('/api/reviews/rating', {
            query: {
                skus: skus.join(','),
            },
            dedupe: 'defer',
            headers: {
                ...csrf ? {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'csrf-token': csrf,
                } : {},
            },
        });

        const rating = computed(() => (
            data.value?.averageRating
                ? Number(data.value.averageRating)
                : 0
        ));

        const totalRatingCount = computed(() => (
            data.value?.totalRatingCount || 0
        ));

        return {
            rating,
            totalRatingCount,
            status,
        };
    };

    const getReviews = (skus: string[], page: MaybeRef<number>, extraKey?: string) => {
        const { data, status } = useFetch('/api/reviews/reviews', {
            query: {
                skus: skus.join(','),
                page,
                // We append an unused dummy query parameter into the request.
                // Nuxt computes the request's key automatically from its
                // parameters (and providing a reactive `key` param is not
                // supported), so this makes it possible to have the same query
                // in different components but without sharing the response
                // data between them.
                // https://github.com/nuxt/nuxt/issues/21532
                // eslint-disable-next-line @typescript-eslint/naming-convention
                ...(extraKey ? { _dummy: extraKey } : {}),
            },
            headers: {
                ...csrf ? {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'csrf-token': csrf,
                } : {},
            },
        });

        const reviews = computed(() => (
            data.value?.items || []
        ));

        const pageCount = computed(() => (
            data.value?.pageCount || 0
        ));

        const totalCount = computed(() => (
            data.value?.totalCount || 0
        ));

        const totalRatings = computed(() => (
            data.value?.totalRatings || []
        ));

        return {
            reviews,
            pageCount,
            totalCount,
            status,
            totalRatings,
        };
    };

    const getIcon = (index: number, rating: MaybeRef<number>): Icon => {
        if (get(rating) >= index) {
            return 'star';
        }

        if (get(rating) >= index - 0.5) {
            return 'star-half';
        }

        return 'star-faded';
    };

    return {
        getRating,
        getIcon,
        getReviews,
    };
}
