import { init, replayIntegration, browserTracingIntegration } from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter();

    const {
        public: {
            sentry: {
                dsn,
                errorSampleRate,
                tracesSampleRate,
                replaysSessionSampleRate,
                replaysOnErrorSampleRate,
                environment,
                release,
            },
        },
    } = useRuntimeConfig();

    if (dsn) {
        init({
            app: nuxtApp.vueApp,
            dsn,
            release,
            environment,
            integrations: [
                browserTracingIntegration({
                    router,
                }),
                replayIntegration(),
            ],
            sampleRate: errorSampleRate,
            tracesSampleRate,
            replaysSessionSampleRate,
            replaysOnErrorSampleRate,
        });
    }
});
