<template>
    <div>
        <v-header />

        <v-breadcrumbs class="mx-sm mb-md mt-sm sm:container sm:mx-auto" />

        <v-404 :error="error" />

        <v-footer />
    </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '~~/types';

defineProps<{
    error: NuxtError
}>();

useSeoMeta({
    title: '404',
});
</script>
