<template>
    <v-header-nav-page class="bg-white px-10 pb-lg text-black">
        <p class="mb-5 text-3xl font-bold">
            {{ subMenu.name }}
        </p>

        <div class="flex flex-col space-y-2">
            <v-header-nav-category-item v-for="child in subMenu.children"
                :key="child.id"
                :item="child"
                @click="$emit('open', child)">
                {{ child.name }}
            </v-header-nav-category-item>
        </div>

        <nuxt-link v-if="subMenu.url"
            :to="subMenu.url"
            class="mr-auto mt-lg rounded border border-gray-100 px-3 py-2 text-black
                hover:border-gray-200 focus:border-gray-200 active:border-gray-400">
            {{ t('main-menu-show-all') }}
        </nuxt-link>
    </v-header-nav-page>
</template>

<script setup lang="ts">
import type { TreeItem } from '~/server/api/navigation.get';

defineProps<{
    subMenu: TreeItem
}>();

defineEmits<{
    (event: 'open', item: TreeItem): void
}>();

const { t } = useI18n();
</script>

<i18n lang="json">
{
    "et": {
        "main-menu-show-all": "Näita kõiki tooteid"
    },
    "fi": {
        "main-menu-show-all": "Näytä kaikki"
    }
}
</i18n>
