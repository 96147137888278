<!-- eslint-disable tailwindcss/no-custom-classname -->
<template>
    <div :id="blok._uid"
        class="relative w-[inherit] space-y-2xl md:mx-md 2xl:mx-0">
        <v-product-recommendations v-slot="{ isLoading, products }"
            :name="blok.nosto_id"
            class="space-y-2xl">
            <div v-if="(blok.title && blok.title.length > 0) || (blok.ingress && blok.ingress.length > 0)"
                class="mx-auto space-y-lg px-sm text-center">
                <v-h2 v-if="blok.title && blok.title.length > 0">
                    {{ addHyphens(blok.title) }}
                </v-h2>

                <p v-if="blok.ingress && blok.ingress.length > 0"
                    class="md:mx-[20%]">
                    {{ blok.ingress }}
                </p>
            </div>

            <v-scrollable v-if="isLoading || (products && products.length > 0)"
                class="flex snap-x scroll-px-sm flex-nowrap space-x-xs px-sm md:scroll-px-0 md:space-x-sm md:px-0">
                <template v-if="isLoading">
                    <v-scrollable-item v-for="i in 10"
                        :key="i"
                        class="w-[160px] shrink-0 snap-start md:w-[260px]">
                        <v-product-card-placeholder />
                    </v-scrollable-item>
                </template>

                <v-scrollable-item v-for="product in products"
                    v-else
                    :key="product.key"
                    class="w-[160px] shrink-0 snap-start md:w-[260px]"
                >
                    <v-elevate-product-card :product="product" />
                </v-scrollable-item>
            </v-scrollable>
        </v-product-recommendations>

        <div v-if="blok.button_link && blok.button_text"
            class="text-center">
            <v-button theme="black"
                size="lg"
                :to="url"
                :aria-label="blok.button_text"
                class="p-sm text-base font-normal uppercase">
                {{ blok.button_text }}
            </v-button>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-nosto' }>
}>();

const { format: formatSbUrl } = useSbUrl();
const { hyphenate: addHyphens } = useHyphenation();

const url = computed(() => formatSbUrl(properties.blok.button_link?.cached_url));
</script>
