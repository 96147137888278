import type { ISbStoryData } from '@storyblok/js';

export function useStoryblokOutlets(): ComputedRef<ISbStoryData[]> {
    const { t } = useI18n({
        useScope: 'global',
    });

    return useStoryblokStories('storyblok-outlets', {
        /* eslint-disable @typescript-eslint/naming-convention */
        sort_by: 'name:asc',
        starts_with: `${t('storyblok.outlets')}/`,
        is_startpage: false,
        per_page: 60,
        /* eslint-enable @typescript-eslint/naming-convention */
    });
}
