<template>
    <div class="flex items-center space-x-sm">
        <div class="relative flex size-12 items-center justify-center overflow-hidden rounded p-2">
            <div class="absolute inset-0 rounded bg-gray-500/5"></div>

            <nuxt-picture v-if="product.listingImage"
                :src="product.listingImage.url"
                height="40"
                loading="lazy"
                width="40"
                class="size-full"
                sizes="xs:40px sm:40px md:40px lg:40px xl:40px 2xl:40px"
                :alt="product.name"
                :img-attrs="{
                    class: 'h-full w-full object-contain',
                }" />
        </div>

        <div class="flex flex-1 items-center justify-between text-sm">
            <div class="flex flex-col space-y-xs">
                <div>
                    <v-subtitle class="font-bold"
                        size="sm">
                        {{ product.title || product.name }}
                    </v-subtitle>

                    <p v-if="product.specification"
                        class="line-clamp-2 leading-tight text-gray-500"
                        :title="product.specification">
                        {{ product.specification }}
                    </p>
                </div>
            </div>
        </div>

        <v-button theme="black"
            size="sm"
            class="uppercase"
            @click="addToCart">
            {{ $t('add') }}
        </v-button>
    </div>
</template>

<script setup lang="ts">
import { type ViewCartQuery } from '~/graphql';
import { useGtm } from '@gtm-support/vue-gtm';
import { useToast } from '~/store/toast';

// eslint-disable-next-line max-len
type RecommendedProduct = Extract<Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['recommendedProducts'][0], { __typename: 'SimpleProduct' }>;

const properties = defineProps<{
    product: RecommendedProduct
}>();

const cart = useCart();
const gtm = useGtm();
const toast = useToast();
const { t } = useI18n();
const gtmValues = useGtmValues();

const gaProduct = computed(() => properties.product
    && gtmValues.productToGa(properties.product, 1));

async function addToCart() {
    try {
        const cartResponse = await cart.addProduct(properties.product.id);
        const cartResponseData = cartResponse.data?.cartProductCreate;
        const cartResponseOk = cartResponseData?.__typename === 'CartProductCreatePayload';

        const cartData = cartResponseOk ? cartResponseData.cart : undefined;

        let gaCartProducts: ReturnType<typeof gtmValues.productToGa>[] = [];
        if (cartData) {
            gaCartProducts = cartData.products.map((cartProduct) => (
                gtmValues.productToGa(cartProduct.product, cartProduct.quantity)
            ));
        }

        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'add_to_cart',
            _clear: true,
            ecommerce: {
                currency: 'EUR',
                value: gaProduct.value.price,
                items: [gaProduct.value],
                ...gtmValues.cart.value,
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        gtm?.trackEvent({
            /* eslint-disable @typescript-eslint/naming-convention */
            event: 'va_add_to_cart',
            _clear: true,
            ecommerce: {
                currency: 'EUR',
                value: cartData ? Number.parseFloat(cartData.price.amount) : 0,
                items: gaCartProducts,
                ...gtmValues.cart.value,
            },
            /* eslint-enable @typescript-eslint/naming-convention */
        });
    } catch (error) {
        toast.add('warning', `${t('toast.cart-warning')}`);
        // eslint-disable-next-line no-console
        console.error(error);
    }
}
</script>
