<template>
    <div>
        <slot :products="products"
            :is-loading="isLoading" />
    </div>
</template>

<script lang="ts" setup>
import { useProductRecommendations } from '~~/store/product-recommendations';

const properties = defineProps<{
    name?: string
}>();

const emit = defineEmits<{
    (event: 'no-products'): void
}>();

const recommendationsStore = useProductRecommendations();
const route = useRoute();
const pageType = inject<string>('pageType', 'default');

recommendationsStore.register(pageType === '404' ? '/404' : route.path);

const isLoading = computed(() => (
    properties.name && properties.name.length > 0 ? recommendationsStore.isLoading : false
));

const placement = computed(() => (
    properties.name && properties.name.length > 0
        ? recommendationsStore.getPlacement(properties.name)
        : undefined
));

const products = computed(() => placement.value?.productGroups.flatMap((group) => group.products) || []);

watch(isLoading, (value) => {
    if (!value && products.value.length === 0) {
        emit('no-products');
    }
});
</script>
