<template>
    <nuxt-loading-indicator />

    <nuxt-layout>
        <nuxt-page />
    </nuxt-layout>
</template>

<script setup lang="ts">
provideHeadlessUseId(() => useId());
</script>
