<template>
    <v-container class="space-y-xl px-sm pt-xl sm:px-0">
        <div class="flex flex-col">
            

            <div class="flex flex-col justify-items-center gap-x-md gap-y-sm
                rounded lg:grid lg:grid-cols-2 lg:grid-rows-1">
                <div v-if="contents?.content.content"
                    class="flex flex-col justify-start text-center lg:col-span-1 lg:w-full">
                    <v-h2 tag="h1"
                        class="pb-md">
                        {{ error.statusCode }} - {{ contents?.content.title }}
                    </v-h2>

                    <p class="pb-md">{{ contents?.content.content }}</p>

                    <nuxt-link to="/">
                        <v-button theme="black">
                            {{ t('back-home') }}
                        </v-button>
                    </nuxt-link>

                    <v-storyblok-nosto v-if="contents?.content.nosto"
                        :blok="contents?.content.nosto[0]"
                        class="my-xl" />
                </div>

                <nuxt-picture class="size-full rounded object-cover lg:col-span-1 lg:row-span-1"
                    provider="storyblok"
                    loading="lazy"
                    height="550"
                    width="550"
                    sizes="xs:100vw sm:100vw md:550px"
                    :src="contents?.content.image?.filename"
                    :alt="contents?.content.image?.filename" />
            </div>
        </div>
    </v-container>
</template>

<script setup lang="ts">
import type { NuxtError } from '~~/types';

defineProps<{
    error: NuxtError
}>();

const { t } = useI18n();

const contents = useStoryblokStory('global/404');

provide('pageType', '404');
</script>

<i18n lang="json">
{
    "et": {
        "back-home": "Tagasi avalehele"
    },
    "fi": {
        "back-home": "Takaisin etusivulle"
    }
}
</i18n>
