<template>
    <div>
        <div class="rounded bg-white">
            <v-placeholder class="aspect-square rounded bg-gray-500/5 before:via-white">
                .......
            </v-placeholder>
        </div>

        <div class="px-2 pb-3 pt-2 md:py-3">
            <v-placeholder class="mb-1 h-4 w-2/3 rounded bg-gray-500/5 before:via-white">
                .......
            </v-placeholder>

            <v-placeholder class="mb-1 h-5 rounded bg-gray-500/5 before:via-white">
                .......
            </v-placeholder>

            <v-placeholder class="mb-3 h-5 w-1/3 rounded bg-red-500/5 before:via-white md:h-7">
                ....
            </v-placeholder>

            <v-placeholder class="h-3 w-1/2 rounded bg-gray-500/5 before:via-white md:h-5">
                .......
            </v-placeholder>
        </div>
    </div>
</template>
