
import * as storyblokRuntime$3LElEhWWBR from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as imageengineRuntime$H7Jnl0OQhb from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/imageengine'
import * as cloudflareRuntime$NpSBtDky7a from '/usr/src/app/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as imgproxyRuntime$0FpyNu4Td9 from '/usr/src/app/providers/imgproxy.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "3xl": 1842
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$3LElEhWWBR, defaults: {"baseURL":"https://a.storyblok.com"} },
  ['imageengine']: { provider: imageengineRuntime$H7Jnl0OQhb, defaults: {"baseURL":"https://19dwwbdw.cdn.imgeng.in"} },
  ['cloudflare']: { provider: cloudflareRuntime$NpSBtDky7a, defaults: {} },
  ['imgproxy']: { provider: imgproxyRuntime$0FpyNu4Td9, defaults: {} }
}
        