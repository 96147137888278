<template>
    <div>
        <v-button theme="black"
            size="md"
            @click="logIn">
            {{ t('login') }}
        </v-button>
    </div>
</template>

<script lang="ts" setup>
defineProps<{
    fontColor?: string
}>();

const { t } = useI18n();
const auth = useAuth();

async function logIn() {
    await auth.login(window.location.pathname);
}
</script>
