<template>
    <component :is="tag"
        :href="to"
        type="button"
        class="text-left text-4xl font-bold tracking-2xl hover:underline md:text-5xl">
        <slot></slot>
    </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

const properties = defineProps<{
    to?: RouteLocationRaw
}>();

const tag = computed(() => {
    if (properties.to) {
        return defineNuxtLink({});
    }

    return 'button';
});
</script>
