import { defineNuxtPlugin } from '#app';

/*
    Plugin for calculating products monthly payment price.
    1. Regular monthly payment (avarda)
    2. Company payment (taxless)
    3. Tuohi payment
*/

export default defineNuxtPlugin(() => ({
    provide: {
        getMonthlyPayment(
            price: number,
            months: number,
            statementFee: number,
            yearInterestRate: number,
        ): number {
            const value = Math.round(
                (
                    (
                        (yearInterestRate / 1200)
                        + ((yearInterestRate / 1200) / (((1 + (yearInterestRate / 1200)) ** months) - 1))
                    )
                    * price * 100
                ) / 100,
            ) + statementFee;
            return value < 8 ? 8 : value;
        },
        getCompanyPayment(
            price: number,
            months: number,
            statementFee: number,
        ): number {
            const value = Math.round((price * (((statementFee / 100) * months) + 1)) / 12);
            return value < 8 ? 8 : value;
        },
        getTuohiPayment(
            price: number,
            monthCount: number,
            isFirst: boolean,
        ): number | null {
            if (monthCount === undefined) {
                const { months } = monthCount;
                if (!months) { return null; }
            }
            if (!monthCount) { return null; }

            price = Math.round((price / monthCount) * 100) / 100;

            if (monthCount === 10) {
                price += (isFirst !== undefined && isFirst) ? 25 : 5;
            } else if (monthCount === 20) {
                price += 5;
            }
            return price;
        },
    },
}));
