import { defineStore, acceptHMRUpdate } from 'pinia';
import type { Toast, ToastTheme } from '~~/types';

interface State {
    items: Toast[]
}

export const useToast = defineStore('toast', {
    state: (): State => ({
        items: [],
    }),

    actions: {
        add(theme: ToastTheme, text: string) {
            const id = Date.now();

            this.items.unshift({
                id,
                theme,
                text,
            });

            setTimeout(() => {
                this.remove(id);
            }, 4000);
        },
        remove(id: number) {
            this.items = this.items.filter((item) => item.id !== id);
        },
    },
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useToast, import.meta.hot));
}
