<template>
    <h-popover class="relative z-20">
        <v-header-button id="cart"
            :aria-label="t('aria.open', { name: t('cart')})"
            :badge="productCount"
            class="rounded transition-colors duration-150
            focus-visible:rounded-2xs focus-visible:outline-2 focus-visible:outline-white
            focus-visible:ring-4 focus-visible:ring-blue-600"
            @click="isOpen = true">
            <v-icon icon="shopping-bag"
                class="text-xl text-[color:var(--color)]"
                :style="{
                    '--color': fontColor
                }"
            />
        </v-header-button>

        <transition-group
            enter-active-class="transition duration-300 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-300 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <h-popover-overlay v-if="newProductId"
                key="cart-overlay"
                static
                class="fixed inset-0 bg-black/30"
                @click="newProductId = undefined" />

            <h-popover-panel v-if="newProductId"
                key="cart-panel"
                static
                @mouseover="stop"
                @mouseleave="start"
                @focusin="stop"
                @focusout="start">
                <v-header-cart-small :cart="cartData"
                    :product-id="newProductId"
                    class="fixed inset-x-0 top-0 z-10 mx-auto
                        bg-white text-[color:var(--color)] sm:absolute
                        sm:left-auto sm:top-12 sm:mx-0"
                    :style="{
                        '--color': fontColor
                    }"
                    @close="newProductId = undefined" />
            </h-popover-panel>
        </transition-group>
    </h-popover>

    <v-modal :is-open="isOpen"
        class="bg-white"
        @close="isOpen = false">
        <v-modal-header @close="isOpen = false">
            {{ t('cart-modal-title') }}
        </v-modal-header>

        <template v-if="fetching || stale">
            <div class="px-sm pb-12 md:px-lg">
                <v-cart-product-skeleton in-modal />

                <p class="my-lg font-sans text-base">{{ t('loading') }}</p>
            </div>
        </template>

        <template v-if="cartData && products.length > 0">
            <div class="px-sm pb-12 md:px-lg">
                <v-page-cart-club-notification v-if="!isLoggedIn && isClubPriceActive"
                    class="mb-lg flex"
                    :products="products"
                    :total-price="cartData.price.amount"
                    vertical />

                <template v-for="(product, index) in products"
                    :key="product.id">
                    <hr v-if="index"
                        class="my-md border-gray-100">

                    <v-header-cart-product :cart-product="product" />
                </template>

                <div v-if="recommendedProducts.length > 0"
                    class="mt-lg space-y-sm">
                    <v-h6>{{ t('gift-products') }}</v-h6>

                    <div class="space-y-xs last:pb-2xl">
                        <template v-for="(product, index) in recommendedProducts"
                            :key="product.id">
                            <hr v-if="index"
                                class="border-gray-100">

                            <v-header-cart-recommended-product :product="product" />
                        </template>
                    </div>
                </div>
            </div>

            <div class="h-max space-y-md bg-gray-50 px-sm py-md lg:px-md">
                <v-header-cart-summary :cart="cartData" />

                <v-header-cart-summary-input :cart="cartData" />

                <div class="my-sm flex space-x-xs">
                    <v-button theme="black"
                        class="flex items-center space-x-sm"
                        @click="copyLink">
                        <v-icon icon="send"
                            class="text-xl" />

                        <span>{{ t('link.share') }}</span>
                    </v-button>

                    <v-button :theme="checkoutButtonTheme"
                        class="flex-1"
                        :class="{
                            'cursor-not-allowed': !isBuyable,
                        }"
                        :disabled="!isBuyable"
                        @click="createOrder">
                        {{ t('go-to-checkout') }}
                    </v-button>
                </div>
            </div>

            <div v-if="relatedProducts.length > 0"
                class="mt-lg space-y-sm px-sm py-md lg:px-md">
                <v-h6>{{ t('related-products') }}</v-h6>

                <div class="space-y-xs last:pb-2xl">
                    <template v-for="(product, index) in relatedProducts"
                        :key="product.id">
                        <hr v-if="index"
                            class="border-gray-100">

                        <v-header-cart-related-product :product="product" />
                    </template>
                </div>
            </div>
        </template>

        <div v-else
            class="flex flex-col items-center px-sm pb-20 md:px-lg">
            <p>{{ t('cart-modal-empty') }}</p>

            <v-icon class="mt-md text-10xl text-red-500"
                icon="store" />
        </div>
    </v-modal>
</template>

<script lang="ts" setup>
import { useMutation, useQuery } from '@urql/vue';
import {
    useClipboard,
    useSessionStorage,
    useTimeoutFn,
    whenever,
} from '@vueuse/core';
import { useToast } from '~/store/toast';
import { useProfile } from '~/store/profile';
import {
    OrderCreateFromCartDocument,
    ProductState,
    ViewCartDocument,
    type OrderCreateFromCartMutation,
    type OrderCreateFromCartMutationVariables,
    type ViewCartQuery,
    type ViewCartQueryVariables,
} from '~~/graphql';

defineProps<{
    fontColor?: string
}>();

const router = useRouter();
const { t } = useI18n();
const { t: tg } = useI18n({ useScope: 'global' });
const { copy, copied } = useClipboard();
const toast = useToast();
const isOpen = ref(false);

const profileStore = useProfile();
const tokenCookie = useCookie('auth0.token');
const isLoggedIn = computed(() => !!tokenCookie.value && !!profileStore.profile);

router.beforeEach(() => {
    isOpen.value = false;
});

const checkoutButtonTheme = computed<'black' | 'checkout'>(() => (
    tg('elements.checkout-button') === 'black' ? 'black' : 'checkout'
));

const { id: cartId } = useCart();
const viewCartVariables = computed(() => ({
    id: cartId.value ?? '',
}));

const { data, stale, fetching } = useQuery<ViewCartQuery, ViewCartQueryVariables>({
    query: ViewCartDocument,
    variables: viewCartVariables,
    pause: computed(() => viewCartVariables.value.id.length === 0),
});

const cartData = computed(() => {
    if (!data.value || data.value.cart.__typename !== 'Cart') {
        return undefined;
    }

    return data.value.cart;
});

const productCount = computed(() => (
    cartData.value?.products.reduce((total, cartProduct) => total + cartProduct.quantity, 0) || 0
));

const products = computed(() => cartData.value?.products || []);

const isBuyable = computed(() => (
    products.value.every((product) => (
        product.product.inventory.isAvailableForPurchase && product.product.state !== ProductState.Draft
    )) ?? false
));

const isClubPriceActive = computed(() => (
    products.value.some((product) => product.product.cartPromotions
        .some((promotion) => promotion.requiresLogin))
));

// eslint-disable-next-line max-len
type RelatedProduct = Extract<Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['products'][0]['product']['relatedProducts']['edges'][0]['node'], { __typename: 'SimpleProduct' }>;

const relatedProducts = computed<RelatedProduct[]>(() => (
    useUniqBy(
        products.value.flatMap((product) => product.product.relatedProducts.edges.map((edge) => edge.node))
            .filter((product): product is RelatedProduct => product.__typename === 'SimpleProduct')
            .filter((product) => (
                !products.value.some((cartProduct) => cartProduct.product.id === product.id)
                && product.inventory.isAvailableForPurchase
            )),
        (product) => product.id,
    )
));

// eslint-disable-next-line max-len
type RecommendedProduct = Extract<Extract<ViewCartQuery['cart'], { __typename: 'Cart' }>['recommendedProducts'][0], { __typename: 'SimpleProduct' }>;

const recommendedProducts = computed(() => (
    useUniqBy(
        cartData.value?.recommendedProducts
            .filter((product): product is RecommendedProduct => product.__typename === 'SimpleProduct')
            .filter((product) => (
                !products.value.some((cartProduct) => cartProduct.product.id === product.id)
                && product.inventory.isAvailableForPurchase
            ))
        || [],
        (product) => product.id,
    )
));

function copyLink() {
    if (!cartData.value) {
        return;
    }

    const {
        public: {
            appUrl,
        },
    } = useRuntimeConfig();

    const url = `${appUrl}/cart/${cartData.value.id}/restore`;

    copy(url);
}

whenever(copied, () => {
    toast.add('success', t('link.copied'));
});

const { $eventbus } = useNuxtApp();
const newProductId = ref<string>();

const { start, stop } = useTimeoutFn(() => {
    newProductId.value = undefined;
}, 3000, { immediate: false });

$eventbus.on('product-added-to-cart', ({ productId }) => {
    newProductId.value = productId;
    start();
});

const {
    executeMutation: executeCreateOrder,
} = useMutation<OrderCreateFromCartMutation, OrderCreateFromCartMutationVariables>(OrderCreateFromCartDocument);

async function createOrder() {
    if (!cartData.value?.id) {
        return;
    }

    const response = await executeCreateOrder({
        cartId: cartData.value.id,
    });

    if (response.data?.orderCreateFromCart.__typename !== 'OrderCreatePayload') {
        return;
    }

    const orderCookie = useCookie('order');
    const orderPaymentData = useSessionStorage('order-payment-data', {});

    orderCookie.value = response.data.orderCreateFromCart.order.id;
    orderPaymentData.value = undefined;

    router.push({
        name: 'checkout',
    });
}
</script>

<i18n lang="json">
{
    "et": {
        "cart-modal-title": "Ostukorv",
        "cart-modal-empty": "Ostukorv on tühi",
        "go-to-checkout": "Suundu maksma",
        "related-products": "Vaata ka neid",
        "gift-products": "Lisa kingitused",
        "link": {
            "share": "Jaga korvi",
            "copied": "Link kopeeritud"
        },
        "loading": "Laen ostukorvi.."
    },
    "fi": {
        "cart-modal-title": "Ostoskori",
        "cart-modal-empty": "Ostoskori on tyhjä",
        "go-to-checkout": "Siirry kassalle",
        "related-products": "Muista myös nämä",
        "gift-products": "Lisää kaupanpäällisesi",
        "link": {
            "share": "Jaa kori",
            "copied": "Linkki kopioitu"
        },
        "loading": "Ladataan ostoskoria.."
    }
}
</i18n>
