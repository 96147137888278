import { defineNuxtPlugin } from '#app';

/*
    Plugin for calculating products taxless price.
*/

export default defineNuxtPlugin(() => ({
    provide: {
        calculateZeroTax(price: number, taxRate: number): number {
            const taxFormatted = Number.parseFloat(`1.${taxRate}`);
            // eslint-disable-next-line radix
            return Number.parseInt((price / taxFormatted).toFixed(0));
        },
    },
}));
