<template>
    <component :is="tag"
        :href="hasSubNav ? undefined : to"
        :type="hasSubNav ? 'button' : undefined"
        class="text-left text-4xl font-bold tracking-2xl hover:underline md:text-5xl"
        :class="{
            'flex items-center justify-between': hasSubNav,
        }">
        <slot></slot>

        <v-icon v-if="hasSubNav"
            icon="chevron-right"
            class="text-3xl" />
    </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router';

const properties = defineProps<{
    hasSubNav?: boolean
    to?: RouteLocationRaw
}>();

const tag = computed(() => {
    if (properties.to) {
        return defineNuxtLink({});
    }

    return 'button';
});
</script>
