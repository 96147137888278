import { createGtm } from '@gtm-support/vue-gtm';
import type { Router } from 'vue-router';

export default defineNuxtPlugin((nuxtApp) => {
    const {
        public: {
            gtmAccountId,
        },
    } = useRuntimeConfig();

    if (!!gtmAccountId && gtmAccountId.length > 0) {
        window.dataLayer = window.dataLayer || [];

        // eslint-disable-next-line no-inner-declarations, prefer-rest-params
        function gtag() { window.dataLayer?.push(arguments); }

        // @ts-expect-error gtag is a special function
        gtag('consent', 'default', {
            /* eslint-disable @typescript-eslint/naming-convention */
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied',
            wait_for_update: 500,
            /* eslint-enable @typescript-eslint/naming-convention */
        });

        nuxtApp.vueApp.use(createGtm({
            id: gtmAccountId,
            vueRouter: nuxtApp.$router as Router,
        }));
    }
});
