export function getCacheKeys() {
    return Object.fromEntries([
        'EntityNotFoundError',
        'Money',
        'CustomerOrderStatistics',
        'OrderStatistics',
        'ProductGroupStatistics',
        'ProductGroupStatisticsGroup',
        'CustomerStatistics',
        'CompanyOrderStatistics',
        'ProductInventory',
        'ProductPricing',
        'ProductSeo',
        'ProductDimensions',
        'ProductCart',
        'ProductAttributeValue',
        'ProductPrice',
        'ProductConfigurableAttributeOption',
        'ProductConfiguration',
        'CategorySeo',
        'CartProductDiscount',
        'OrderShippingInfo',
        'OrderPaymentInfo',
        'OrderTaxLine',
        'OrderData',
        'CatalogPromotionPercentDiscount',
        'OrderShippingInfoHakonen',
        'OrderShippingInfoLocalDelivery',
        'OrderShippingInfoMatkahuolto',
        'OrderShippingInfoOmniva',
        'OrderShippingInfoPickUpFromStore',
        'OrderShippingInfoPosti',
        'PromotionStyleColorInfo',
        'CatalogPromotionFixedAmount',
        'CatalogPromotionFixedAmountDiscount',
        'PromotionStyleTheme',
        'CartPromotionPercentDiscount',
        'PromotionStyleFontInfo',
        'PromotionStyleProductPage',
    ].map((key) => [key, () => null]));
}
