export default defineNuxtRouteMiddleware(async () => {
    if (process.server) {
        return;
    }

    const auth = useAuth();

    if (!auth.isAuthenticated()) {
        try {
            await auth.getAccessToken();
        } catch {
            //
        }
    }
});
